import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css'
import '@/assets/css/index.scss'
import '@/assets/css/font/iconfont.css'
import "@/assets/css/fontCommon/iconfont.css"
import ImagePreview from "./plugins/imagePreview";
import VideoPreview from "./plugins/videoPreview";
import FilePreview from "./plugins/filePreview";
Vue.use(ImagePreview)
Vue.use(VideoPreview)
Vue.use(FilePreview)
import myMoreText from './components/common/myMoreText'
Vue.component('myMoreText', myMoreText)
Vue.config.productionTip = false;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import { apiErrorDataSendServe, 
  dealUpdate } from '@/utils/tool'
const _errLog = console.error
console.error = (...args) => {
  _errLog(...args)
  if (args.length == 2) {
    const remark = args[0]
    const dataObj = args[1]
    console.log("dataObj: ", Object.prototype.toString.call(dataObj))
    if(!dataObj){
      return
    }
    if (Object.prototype.toString.call(dataObj) == '[object Error]') {
      const { message, stack, name } = dataObj
      console.log("message, stack, name: ", message, stack, name)
      const errData = {
        type: 'error-grammar',
        msg: message,
        originType: name,
        remark,
        stack,
      }
      apiErrorDataSendServe(errData)
    } else if (Object.prototype.toString.call(dataObj) == '[object Object]') {
      if (dataObj.code) {
        const { code, msg } = dataObj
        console.log("code, msg: ", code, msg)
        const errData = {
          code,
          type: 'error-api',
          msg,
          remark,
        }
        apiErrorDataSendServe(errData)
      } else  {
        const { message, path, status } = dataObj
        const errData = {
          status,
          type: 'error-api',
          msg: message,
          path,
          remark,
        }
        apiErrorDataSendServe(errData)
      }

    }
  }
}

Vue.use(ElementUI, { size: 'small' });

// 自定义指令
import '@/directives/index.js'

Vue.prototype.msgSuccess = (...args) => {
  if (!args[0]) {
    return
  }
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.success(...args)
}
Vue.prototype.msgInfo = (...args) => {
  if (!args[0]) {
    return
  }
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.info(...args)
}
Vue.prototype.msgWarn = (...args) => {
  if (!args[0]) {
    return
  }
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.warning(...args)
}
Vue.prototype.msgError = (...args) => {
  console.log('msgError args: ', args)
  if (!args[0]) {
    return
  }
  Vue.prototype.$message.closeAll()
  Vue.prototype.$message.error(...args)
}

Vue.prototype.publicBaseUrl = process.env.VUE_APP_PUBLIC_BASE_URL

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
window.showUpdateTip = false
setTimeout(() => {
  dealUpdate(vm)
}, 2 * 60 * 1000)
setInterval(() => {
  dealUpdate(vm)
},30 * 60 * 1000)
let reloadFlag = false
window.addEventListener('error', handleListenerError, true);
function handleListenerError(eventErr) {
  console.log("eventErr: ", eventErr, eventErr.srcElement.src, eventErr.message)
  if(eventErr.srcElement.src.includes("/common/js/common.js")){
    return
  }
  const origin = location.origin
  if ((eventErr.srcElement.localName == 'link' && eventErr.srcElement.href && eventErr.srcElement.href.indexOf(origin) == 0) || (eventErr.srcElement.localName == 'script' && eventErr.srcElement.src && eventErr.srcElement.src.indexOf(origin) == 0)) {
    if (reloadFlag) {
      return
    }
    const errData = {
      type: 'error-file',
      baseURI: eventErr.srcElement.baseURI,
      msg: eventErr.srcElement.href,
    }
    apiErrorDataSendServe(errData)
    //reloadFlag = true
    //alert('因版本更新，页面需重新载入!');
    //window.location.reload();
  } else if (eventErr.srcElement.localName == 'link') {
    const errData = {
      type: 'error-link',
      baseURI: eventErr.srcElement.baseURI,
      msg: eventErr.srcElement.href,
    }
    apiErrorDataSendServe(errData)
  } else if (eventErr.srcElement.localName == 'script') {
    const errData = {
      type: 'error-script',
      baseURI: eventErr.srcElement.baseURI,
      msg: eventErr.srcElement.src,
    }
    apiErrorDataSendServe(errData)
  } else if (eventErr.error) {
    const { message, stack } = eventErr.error
    const errData = {
      type: 'error-other',
      stack: stack,
      msg: message,
    }
    apiErrorDataSendServe(errData)
  }
  eventErr.preventDefault()
}